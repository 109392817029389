import { Injectable } from '@angular/core';
import { filter, find, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { Race } from '../../ranking/races/race';
import { Driver } from '../drivers/driver';
import { combineLatest, Observable } from 'rxjs';
import { Ranking } from '../../ranking/ranking';
import { RacesService } from '../../ranking/races/races.service';
import { DriversService } from '../drivers/drivers.service';
import { RankingsService } from '../../ranking/rankings.service';

@Injectable({
  providedIn: 'root'
})
export class ResultsImport3Service {

  constructor(
    private racesService: RacesService,
    private driversService: DriversService,
    private rankingsService: RankingsService
  ) {
  }

  loadRace(raceId?: number): Observable<[Race, Ranking[], Observable<Driver[]>]> {
    let race$: Observable<Race>;
    if (raceId) {
      race$ = this.racesService.requestRaces().pipe(
        switchMap(races => races),
        find(race => race.id === raceId)
      );
    } else {
      race$ = this.racesService.requestRaces().pipe(
        filter(races => !!races?.length),
        mergeMap(races => races),
        find(race => !!race.resultsCouchDbName)
      );
    }
    return combineLatest([
      race$,
      this.rankingsService.requestRankings()
    ]).pipe(
      map(([race, rankings]: [Race, Ranking[]]) => ([
        race,
        rankings,
        this.driversService.requestDrivers(race, {pollingInterval: 10 * 1000})
      ]))
    );
}
}
