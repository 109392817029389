import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StandingsConfig } from '../standings-config';
import { ResultsConfig } from '../../results/results-config/results-config';

type TCategoryPreset = {
  label: string,
  category?: number[]
};

@Component({
  selector: 'mc-standings-moderation',
  templateUrl: './standings-moderation.component.html',
  styleUrls: ['./standings-moderation.component.scss']
})
export class StandingsModerationComponent implements OnInit {

  categories: TCategoryPreset[] = [
    { label: 'All categories' },
    { label: 'NA/NB1.6 + NC1.8 + ND1.5 only', category: [1] },
    { label: 'NA/NB1.8 only', category: [2] },
    { label: 'NC2.0/2.5 + ND2.0 + Fiata', category: [3] },
    { label: 'Miata Turbo only', category: [5] },
    { label: 'SPEC NA/NB only', category: [6] },
    { label: 'SPEC NC only', category: [7] },
    { label: 'SPEC ND only', category: [8] },
    { label: 'OPEN class only', category: [10] },
    { label: 'MX-5 only', category: [1, 2, 3, 5, 6, 7, 8] }
  ];

  categoryIndex: number;

  @Input() standingsConfig: StandingsConfig;
  @Input() resultsConfig: ResultsConfig;

  @Output() standingsConfigChanged = new EventEmitter<StandingsConfig>();
  @Output() resultsConfigChanged = new EventEmitter<ResultsConfig>();


  set group(group: string|'') {
    this.standingsConfig.group = group === '' ? undefined : +group;
    this.emitStandingsConfig();
  }

  get group(): string {
    return this.standingsConfig.group === undefined ? '' : '' + this.standingsConfig.group;
  }

  set category(spec: TCategoryPreset) {
    this.categoryIndex = this.categories.indexOf(spec);
    this.standingsConfig.category = this.categories[this.categoryIndex].category;
    this.emitStandingsConfig();
  }

  get category(): TCategoryPreset {
    return this.categories[this.categoryIndex];
  }

  set stock(stock: string|'') {
    this.standingsConfig.stock = stock === '' ? undefined : !!+stock;
    this.emitStandingsConfig();
  }

  get stock(): string {
    return this.standingsConfig.stock === undefined ? '' : +this.standingsConfig.stock + '';
  }

  set women(women: string|'') {
    this.standingsConfig.women = women === '' ? undefined : !!+women;
    this.emitStandingsConfig();
  }

  get women(): string {
    return this.standingsConfig.women === undefined ? '' : +this.standingsConfig.women + '';
  }

  set yt(yt: string|'') {
    this.standingsConfig.yt = yt === '' ? undefined : !!+yt;
    this.emitStandingsConfig();
  }

  get yt(): string {
    return this.standingsConfig.yt === undefined ? '' : +this.standingsConfig.yt + '';
  }

  set minimumLapSeconds(n: number) {
    this.resultsConfig.minimumLapSeconds = +n;
    this.emitResultsConfig();
  }

  get minimumLapSeconds(): number {
    return this.resultsConfig.minimumLapSeconds;
  }

  set maximumLapSeconds(n: number) {
    this.resultsConfig.maximumLapSeconds = +n;
    this.emitResultsConfig();
  }

  get maximumLapSeconds(): number {
    return this.resultsConfig.maximumLapSeconds;
  }

  set rankingLapsNumber(n: number) {
    this.resultsConfig.rankingLapsNumber = +n;
    this.emitResultsConfig();
  }

  get rankingLapsNumber(): number {
    return this.resultsConfig.rankingLapsNumber;
  }

  constructor() { }

  ngOnInit() {
    this.emitStandingsConfig();
    this.emitResultsConfig();
  }

  emitStandingsConfig() {
    this.standingsConfigChanged.emit({... this.standingsConfig});
  }

  emitResultsConfig() {
    this.resultsConfigChanged.emit({... this.resultsConfig});
  }

}
