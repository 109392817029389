import { Component, OnInit } from '@angular/core';
import { RankingsService } from '../rankings.service';
import { GateReadingsService } from '../../gepard/gate-readings/gate-readings.service';
import { RacesService } from '../races/races.service';
import { StandingsFilterPipe } from '../standings/standings-filter.pipe';
import { ResultsService } from '../results/results.service';
import { DriversService } from '../../gepard/drivers/drivers.service';
import { StandingsService } from '../standings/standings.service';
import { TagsService } from '../../gepard/tags/tags.service';
import { Race } from '../races/race';
import { of, forkJoin } from 'rxjs';
import { map, mergeMap, tap, toArray } from 'rxjs/operators';
import { Driver } from '../../gepard/drivers/driver';
import { Standing } from '../standing';
import { Result } from '../results/result/result';
import { ResultsFilterPipe } from '../results/results-config/results-filter.pipe';
import { ResultsConfig } from '../results/results-config/results-config';
import { SeasonPointsPipe } from '../../seasons/season-points.pipe';
import { Ranking } from '../ranking';

@Component({
  selector: 'mc-season-results',
  templateUrl: './season-results.component.html',
  styleUrls: ['./season-results.component.scss']
})
export class SeasonResultsComponent implements OnInit {

  loading = false;

  driversStandings = [];

  RANKING_RACES_NUMBER = 7;

  raceRankings: {
    race: Race,
    drivers: Driver[],
    standings: Standing[]
  }[] = [];

  rankings = [];

  constructor(
    private driversService: DriversService,
    private tagsService: TagsService,
    private gateReadingsService: GateReadingsService,
    private racesService: RacesService,
    private standingsService: StandingsService,
    private standingsFilter: StandingsFilterPipe,
    private resultsService: ResultsService,
    private rankingsService: RankingsService,
    private resultsFilter: ResultsFilterPipe,
    private seasonPointsFilterPipe: SeasonPointsPipe
  ) {
  }

  ngOnInit() {
    // this.loading = true;
    forkJoin([
      this.rankingsService.requestRankings().pipe(
        tap((rankings: Ranking[]) => this.rankingsService.rankings = rankings)
      ),
      this.racesService.requestRaces().pipe(
        map(races => races.filter(race => race.resultsJsonFile)),
        tap(races => this.racesService.races = races),
        mergeMap(race => race),
        map(race => forkJoin([
          of(race),
          this.driversService.requestDrivers(race),
          this.resultsService.requestResults(race.resultsJsonFile)
        ])),
        mergeMap(all => all),
        map(([race, drivers, results]: [Race, Driver[], Result[]]) => {
          this.driversService.drivers = drivers;
          const standings = this.standingsService.fromResults(this.resultsFilter.transform(results, race.resultsConfig));
          return {
            race,
            drivers,
            standings
          };
        }),
        toArray()
      )
    ])
      .subscribe(([rankings, raceRankings]) => {
        this.raceRankings = raceRankings
          .filter(raceRanking => raceRanking.race.pointsMultiplier > 0)
          .sort((a, b) => {
          if (a.race.date > b.race.date) {
            return 1;
          } else if (a.race.date < b.race.date) {
            return -1;
          } else {
            return 0;
          }
        });

        this.rankings = rankings.map(ranking => {
          const driversStandingsByStartNumber = {};
          this.raceRankings.forEach(raceRanking => {
            let standings = this.standingsFilter.transform(raceRanking.standings, ranking.standingsConfig);
            standings = this.recalculate(standings, raceRanking.race.resultsConfig);

            standings.forEach(standing => {
              if (!driversStandingsByStartNumber[standing.driver.startNumber]) {
                driversStandingsByStartNumber[standing.driver.startNumber] = {
                  name: standing.driver.name,
                  startNumber: standing.driver.startNumber,
                  racePoints: {},
                  racePositions: {},
                  seasonPoints: 0
                };
              }
            });

            this.standingsService.recalculateStandingsBestLap(standings);

            standings.forEach(standing => {
              driversStandingsByStartNumber[standing.driver.startNumber].racePositions[raceRanking.race.id] = standing.position;
              driversStandingsByStartNumber[standing.driver.startNumber].racePoints[raceRanking.race.id] = this.seasonPointsFilterPipe
                .transform(standing.position, raceRanking.race.pointsMultiplier, standing.isBastLap);
            });
          });

          for (const i in driversStandingsByStartNumber) {
            if (driversStandingsByStartNumber.hasOwnProperty(i) && driversStandingsByStartNumber[i].racePoints) {
              const racesPoints = Object.values(driversStandingsByStartNumber[i].racePoints);
              racesPoints.sort((a: number, b: number) => a - b);
              racesPoints.slice(-1 * (ranking.seasonRankingRacesLimit || this.RANKING_RACES_NUMBER))
                .forEach(racePoints => driversStandingsByStartNumber[i].seasonPoints += racePoints);
              driversStandingsByStartNumber[i].racePositionsHash = Object.values(driversStandingsByStartNumber[i].racePositions)
                .sort((a: number, b: number) => a - b)
                .map(racePosition => ('00' + racePosition).slice(-3))
                .join('');
            }
          }

          const driversStandings = Object.values<{
              racePoints: { number },
              racePositions: { number },
              racePositionsHash: number[],
              seasonPoints: number,
              position: number
            }>(driversStandingsByStartNumber)
            .sort((a, b) => {
              if (a.seasonPoints !== b.seasonPoints) {
                return b.seasonPoints - a.seasonPoints;
              } else if (a.racePositionsHash < b.racePositionsHash) {
                return -1;
              } else if (a.racePositionsHash > b.racePositionsHash) {
                return 1;
              } else {
                return 0;
              }
            });

          let position = 0;
          let previousDriverStanding;
          driversStandings.forEach(driverStanding => {
            if (previousDriverStanding) {
              if (previousDriverStanding.seasonPoints !== driverStanding.seasonPoints) {
                position++;
              } else if (previousDriverStanding.racePositionsHash !== driverStanding.racePositionsHash) {
                position++;
              }
            } else {
              position++;
            }
            driverStanding.position = position;
            previousDriverStanding = driverStanding;
          });

          return {
            driversStandings,
            ranking
          };
        });
      });
  }

  recalculate(standings: Standing[], resultsConfig: ResultsConfig) {
    standings
      .forEach(standing => {
        standing.rankingResults = this.recalculateResults(standing.results, resultsConfig);
        standing.rankingResultsAverage = this.average(standing.rankingResults.map(result => result.microtime));
      });
    return this.recalculateRanking(standings);
    // this.standings.forEach(s => s.results.forEach(r => this.resultsMetaService.get(r)));
    // this.resultsMetaService.recalculateDuplicates();
  }

  recalculateResults(results: Result[], resultsConfig: ResultsConfig): Result[] {
    return this.resultsFilter.transform(results, resultsConfig)
      .sort((a, b) => a.microtime - b.microtime)
      .slice(0, resultsConfig.rankingLapsNumber);
  }

  recalculateRanking(standings: Standing[]) {
    standings.sort((a, b) => a.rankingResults.length <= 0 ? 1 : a.rankingResultsAverage - b.rankingResultsAverage);
    standings.forEach((standing, i) => {
      standing.position = i + 1;
    });
    return standings;
  }

  average(results, n = 3) {
    let i;
    let sum = 0;
    for (i = 0; i < results.length && (n <= 0 || i < n); i++) {
      sum += results[i];
    }
    return i ? (sum / i) : null;
  }

}
